<template>
  <section class="section tw-tools-vrceve-x-form">
    <form @submit.prevent="handleOnSubmit">
      <h1 class="title">VRChatイベントカレンダー Xアカウント投稿依頼フォーム</h1>
      <p class="subtitle">VRChatイベントカレンダーのXアカウント <a href="https://x.com/vrcevent_info" target="_blank">@vrcevent_info</a> へイベント情報の投稿を依頼することができます。以下のフォームに投稿したい内容を記載してください。</p>

      <div class="field">
        <label class="label">名前</label>
        <div class="control">
          <input v-model="formData.name" class="input" type="text" placeholder=""  required />
        </div>
      </div>

      <div class="field">
        <label class="label">投稿内容</label>
        <p class="help" style="margin-bottom:.5rem;">２５５文字以内で入力してください。</p>
        <div class="control">
          <textarea v-model="formData.text" class="textarea" placeholder=""  maxlength="255" required></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">添付画像</label>
        <div class="control">
          <div class="file has-name is-boxed">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" @change.prevent="handleChangeFiileField" ref="fileInput" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label"> 添付画像を選択．．． </span>
              </span>
              <span class="file-name">{{ selectedFileName }}</span>
            </label>
          </div>
        </div>
      </div>

      <article class="message is-warning">
        <div class="message-header">
          <p>
            <span class="icon">
              <i class="fa-solid fa-triangle-exclamation"></i>
            </span>
            投稿処理について</p>
        </div>
        <div class="message-body">
          このフォームにて依頼していただいた内容は内容を確認したうえで投稿処理を行っております。ご依頼いただいた内容がすべて投稿されるものではございません。投稿処理は数日かかる場合がございます。
          ご依頼いただいた内容を投稿できなかった場合、投稿が完了した場合などのご連絡は行っておりません。Xの投稿制限等に従い、ご依頼いただいた内容は編集の上投稿される場合がございます。あらかじめご了承ください。
        </div>
      </article>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" v-model="formData.agree">
            上記の注意事項を確認しました。
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button
           type="submit"
           class="button is-link"
           :class="additionalSubmitClass"
           :disabled="isDisabledSubmitButton"
          >送信</button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      selectedFile: null,

      formData: {
        name  : '',
        email : '',
        text  : '',
        agree : false
      },

      isDisabledSubmitButton : false,
      additionalSubmitClass : {
        'is-loading'  : false
      },

      uploadFileName : '',
    }
  },

  computed : {
    backendURL(){
      return process.env.VUE_APP_BACKEND_URL;
    },

    agreeValue(){
      return this.formData.agree;
    },

    selectedFileName(){
      return this.selectedFile ? this.selectedFile.name : '';
    }
  },

  watch : {
    agreeValue : {
      handler(value){
        this.isDisabledSubmitButton = !value;
      },
      immediate : true
    }
  },

  methods: {
    handleChangeFiileField(event){
      this.selectedFile = event.target.files[0];
    },

    buildFormData(){
      const formData = new FormData();

      if(this.selectedFile !== null){
        formData.append('image', this.selectedFile);
      }

      for (const key in this.formData) {
        formData.append(key, this.formData[key]);
      }

      return formData;
    },

    formValidation(){
      if(this.formData.name === ''){
        alert('名前を入力してください');
        return false;
      }

      if(this.formData.text === ''){
        alert('投稿内容を入力してください');
        return false;
      }

      if(!this.formData.agree){
        alert('注意事項に同意してください');
        return false;
      }

      if(!this.formData.text.length > 255){
        alert('投稿内容は255文字以内で入力してください');
        return false;
      }

      return true;
    },
    
    async handleOnSubmit() {
      if(!this.formValidation()) return;

      this.additionalSubmitClass['is-loading'] = true;

      const endpointPath = '/api/vrceve/x_post_form';

      const handler = axios.create({
        baseURL           : this.backendURL,
        withCredentials   : true,
        headers: {
          'Accept'            : 'application/json',
          'X-Requested-With'  : 'XMLHttpRequest',
          'Content-Type'      : 'multipart/form-data'
        }
      });

      const formData = this.buildFormData();

      const results = await handler.post(endpointPath, formData);

      this.additionalSubmitClass['is-loading'] = false;

      this.$nextTick(() => {
        if(results){
          alert('投稿に成功しました');

          this.formData.name  = '';
          this.formData.email = '';
          this.formData.text  = '';
          this.formData.agree = false;

          this.$refs.fileInput.value = '';
          this.selectedFile = null;
          this.selectedFileName = '';
        }else{
          alert('投稿に失敗しました');
        }
      });
    }
  },

  mounted() {
    document.title = 'VRChatイベントカレンダー Xアカウント投稿依頼フォーム';
  }
}
</script>

<style lang="scss">
section.section.tw-tools-vrceve-x-form{
  p.subtitle{
    margin-bottom: 1rem;
  }

  article.message{
    margin-bottom:1rem;
  }

  div.field{
    margin-bottom:1rem;
  }
}
</style>