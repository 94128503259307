import { createRouter, createWebHistory } from 'vue-router'

import Index from '@/Pages/AppIndex.vue'

import VRCEveXPostForm from '@/Pages/VRCEveXPostForm.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path      : '/',
      name      : 'index',
      component : Index,
      // beforeEnter : async (to, from, next) => {
      //   const layoutStore = useLayoutStore();
      //   layoutStore.updateCurrentAppPosition('index');

      //   next();
      // }
    },

    {
      path      : '/vrceve/x_post_form',
      name      : 'vrceve.x_post_form',
      component : VRCEveXPostForm,
      // beforeEnter : async (to, from, next) => {
      //   const layoutStore = useLayoutStore();
      //   layoutStore.updateCurrentAppPosition('index');

      //   next();
      // }
    },
  ]
});

export default router;